import { useToast } from '@chakra-ui/react'
import type React from 'react'
import { useHistory } from 'react-router-dom'
import InfoodTable from '../../../../../components/InfoodTable/InfoodTable'
import KycStatusBox from '../../../../../components/KycStatus'
import PageWrapper from '../../../../../components/Pagewrapper'
import { useGetUsersQuery } from '../../../../../redux/services/coreApi'
import type { KycStatus } from '../../../../../types/coreApi-types'

type ClientsScreenProps = {}

const ClientsScreen: React.FC<ClientsScreenProps> = ({}) => {
  const toast = useToast()
  const history = useHistory()

  const { data: clients, isLoading } = useGetUsersQuery(null)

  return (
    <PageWrapper title="Clients">
      <div className="">
        <InfoodTable
          isLoading={isLoading}
          onClick={v => {
            history.push(`/app/admin/clients/${v.id}`)
          }}
          emptyDataRender={<div className="text-xl mb-3">No data</div>}
          menuActions={[
            {
              title: 'See details',
              onClick: v => {
                history.push(`/app/admin/clients/${v.id}`)
              },
              hidden: v => false,
            },
          ]}
          defaultData={[
            ...(clients?.map(item => {
              return {
                ...item,
                kycStatus: item.kycDetails.kycStatus,
                country: item.country.name,
                vatNumber: item.vatNumber,
                questionnaireStatus: item.kycDetails.questionnaireStatus,
              }
            }) ?? []),
          ]}
          schema={[
            {
              header: 'Account type',
              accessorKey: 'role',
              type: 'status',
              priority: 1,
              newlyAdded: 'createdAt',
            },
            {
              cardTitle: true,
              header: 'Name',
              accessorKey: 'name',
              type: 'string',
              priority: 2,
            },
            {
              header: 'KYC/KYB',
              accessorKey: 'kycStatus',
              type: 'status',
              priority: 3,
              cellValueWrapper: (value, originalValue, row) => (
                <div className="w-min">
                  <KycStatusBox
                    ignoreKyc
                    kycStatus={
                      row?.isSuspended ? 'Suspended' : (value as KycStatus)
                    }
                  />
                </div>
              ),
              initialSorted: true,
            },

            {
              header: 'Email',
              accessorKey: 'email',
              type: 'string',
              priority: 4,
            },
            {
              header: 'Phone',
              accessorKey: 'phone',
              type: 'string',
              priority: 5,
            },
            {
              header: 'Date created',
              accessorKey: 'createdAt',
              type: 'date',
              priority: 6,
            },
            {
              header: 'Address',
              accessorKey: 'address',
              type: 'string',
              priority: 7,
            },
            {
              header: 'Country',
              accessorKey: 'country',
              type: 'status',
              priority: 8,
            },

            {
              header: 'VAT number',
              accessorKey: 'vatNumber',
              type: 'string',
              priority: 9,
              cellValueWrapper: (value, originalValue) => <div>{value}</div>,
            },
            {
              header: 'Questionnaire status',
              accessorKey: 'questionnaireStatus',
              type: 'status',
              priority: 10,
              cellValueWrapper: (value, originalValue) => (
                <div className="w-min">
                  <KycStatusBox kycStatus={value as KycStatus} ignoreKyc />
                </div>
              ),
            },
          ]}
        />
      </div>
    </PageWrapper>
  )
}

export default ClientsScreen
